import { css } from 'styled-components';

export const colors = {
  brand: '#FAB531',
  lightBrand: '#FFE0A9',
  text: '#000000',
};

export const sizes = {
  phoneOnly: 659,
  tabletPortraitUp: 660,
  tabletLandscapeUp: 900,
  laptopUp: 1000,
  desktopUp: 1200,
  bigDesktopUp: 1400,
  hugeDesktopUp: 1600,
};

export const forSize = Object.keys(sizes).reduce((accumulator, sizeLabel, index) => {
  const mediaQueryTemplateFn =
    index > 0
      ? (...args) => css`
          @media (min-width: ${sizes[sizeLabel]}px) {
            ${css(...args)};
          }
        `
      : (...args) => css`
          @media (max-width: ${sizes[sizeLabel]}px) {
            ${css(...args)};
          }
        `;

  accumulator[sizeLabel] = mediaQueryTemplateFn;
  return accumulator;
}, {});
